import 'leaflet/dist/leaflet.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import L from 'leaflet'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet'

import { Poi, Props as PoiProps } from './Poi'

export interface Props {
  address?: string
  googleMapsURL?: string
  languageCode: string
  longitude?: string
  latitude?: string
  poiList?: PoiProps[]
  title?: string
}

export const Directions = memo(function Directions({
  address,
  googleMapsURL,
  languageCode,
  longitude,
  latitude,
  poiList,
  title,
}: Props) {
  const map = useRef(null)
  const [markerIcon, setMarkerIcon] = useState(L.divIcon)
  const [markerHovered, setMarkerHovered] = useState(-1)

  const MouseEnter = (i: number) => {
    setMarkerHovered(i)
  }

  const MouseLeave = () => {
    setMarkerHovered(-1)
  }

  const bounds = [] as any

  bounds.push([latitude, longitude])

  if (poiList && poiList?.length > 1) {
    poiList.map((p, _i) => {
      bounds.push([p.latitude, p.longitude])
    })
  }

  useEffect(() => {
    setMarkerIcon(
      L.divIcon({
        iconSize: [60, 72],
        iconAnchor: [30, 72],
        html: `<svg xmlns="http://www.w3.org/2000/svg" width="60" height="72" viewBox="0 0 60 72"><g transform="translate(-536 -2150)"><circle cx="30" cy="30" r="30" transform="translate(536 2150)" fill="#3e9fb1" /><path d="M17.207.563C17,.5,16.8.436,16.6.37A11.315,11.315,0,0,0,14.091,0h-.829a12.171,12.171,0,0,0-3.122.5A14.445,14.445,0,0,0,3.4,4.63c-7.017,8.195-2.583,21.554,7.926,24.71l.026.011A14.621,14.621,0,0,0,14.676,30V19.319c5.459-.04,9.173-2.957,9.818-7.72A10.081,10.081,0,0,0,17.207.563M16.145,15.2l-1.514.554v3.523a6.615,6.615,0,0,1-6.617-7.02,6.065,6.065,0,0,1,6.762-6.105,4.768,4.768,0,0,1,4.466,4.13,4.258,4.258,0,0,1-3.1,4.918" transform="translate(553.207 2165)" fill="#fff" /><path d="M8.5,0,17,14H0Z" transform="translate(574 2222) rotate(180)" fill="#3e9fb1" /></g></svg>`,
        className: 'main-icon',
      }),
    )
  }, [])

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}

      {address ? (
        <FadeInUp>
          <Address>{address}</Address>
        </FadeInUp>
      ) : null}

      {typeof window !== undefined && latitude && longitude ? (
        <Map
          center={[Number(latitude), Number(longitude)]}
          dragging={L.Browser && L.Browser.mobile ? false : true}
          zoom={14}
          scrollWheelZoom={false}
          whenCreated={(mapInstance: any) => {
            map.current = mapInstance

            mapInstance.fitBounds(bounds)
          }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
          <Marker
            icon={markerIcon}
            position={[Number(latitude), Number(longitude)]}
          />

          {poiList
            ? uniq(poiList).map((item, index) =>
                item.latitude && item.longitude ? (
                  <Marker
                    key={index}
                    icon={
                      typeof window !== 'undefined'
                        ? L.divIcon({
                            iconSize: [30, 30],
                            iconAnchor: [15, 15],
                            html: (index + 1).toString(),
                            className:
                              markerHovered === index ? 'active' : undefined,
                          })
                        : undefined
                    }
                    position={[
                      Number(item.latitude) || 0,
                      Number(item.longitude) || 0,
                    ]}
                  />
                ) : null,
              )
            : null}
        </Map>
      ) : null}

      <Wrapper row space="between" wrap>
        {poiList ? (
          <List>
            {poiList.map((item, index) => (
              <Poi
                className={markerHovered === index ? 'active' : undefined}
                key={index}
                idx={index}
                onMouseEnter={() => MouseEnter(index)}
                onMouseLeave={MouseLeave}
                {...item}
              />
            ))}
          </List>
        ) : null}

        {googleMapsURL ? (
          <GoogleMapsCTA
            label={useVocabularyData('open-google-maps', languageCode)}
            URL={googleMapsURL}
            rel="noreferrer"
            target="_blank"
          />
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark3};
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  padding: 6.25rem 15.486vw;
  text-align: center;

  @media (max-width: 1199px) {
    padding: 5.375rem 1.5rem;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 4.375rem;

  @media (max-width: 1199px) {
    font-size: 2.1875rem;
    line-height: 2.625rem;
  }
`

const Address = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 2.5rem;
  margin-top: 1.25rem;
  text-decoration: underline;

  @media (max-width: 1199px) {
    line-height: 2.125rem;
  }
`

const Map = styled(MapContainer)`
  width: 100%;
  height: 55.6vh;
  margin-top: 4rem;

  .leaflet-marker-icon {
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    border: 0;
    border-radius: 50%;
    line-height: 1.875rem;
    &.active {
      background: ${({ theme }) => theme.colors.variants.primaryLight};
    }
    &.main-icon {
      background: none;
      border-radius: 0;
      position: relative;
      svg {
        position: relative;
        z-index: 2;
      }
    }
  }

  .leaflet-left {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    z-index: 400;
    .leaflet-control {
      border: 0;
      border-radius: 0;
      margin: 0;
      a {
        width: 3.4375rem;
        height: 3.4375rem;
        line-height: 2.875rem;
        background: ${({ theme }) => theme.colors.variants.neutralLight4};
        border: 0;
        border-radius: 0% !important;
        position: relative;
        text-indent: -9999px;
        transition: 0.2s ease-out;
        &.leaflet-control-zoom-in,
        &.leaflet-control-zoom-out {
          &:hover {
            background: ${({ theme }) => theme.colors.variants.neutralDark3};
            &:before,
            &:after {
              background: ${({ theme }) => theme.colors.variants.neutralLight4};
            }
          }
          &:before {
            content: '';
            width: 1.125rem;
            height: 0.125rem;
            background: ${({ theme }) => theme.colors.variants.neutralDark1};
            position: absolute;
            top: 50%;
            left: 50%;
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }
        &.leaflet-control-zoom-in {
          &:after {
            content: '';
            width: 0.125rem;
            height: 1.125rem;
            background: ${({ theme }) => theme.colors.variants.neutralDark1};
            position: absolute;
            top: 50%;
            left: 50%;
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }
        &.leaflet-disabled {
          opacity: 0.2;
          pointer-events: none;
          &:hover {
            opacity: 0.2;
          }
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .leaflet-bottom {
    display: none;
  }

  @media (max-width: 1199px) {
    height: 18.75rem;

    .leaflet-left {
      display: none;
    }
  }
`

const Wrapper = styled(FlexBox)`
  margin-top: 1.875rem;

  @media (max-width: 1199px) {
    display: block;
  }
`

const List = styled.div``

const GoogleMapsCTA = styled(Button)`
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  &:before,
  &:after {
    background: ${({ theme }) => theme.colors.variants.neutralLight5};
  }

  @media (max-width: 1199px) {
    margin-top: 3.125rem;
  }
`
