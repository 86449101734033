import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  className?: string
  idx?: number
  latitude: string
  longitude: string
  label: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export const Poi = memo(function Poi({
  className,
  idx,
  label,
  onMouseEnter,
  onMouseLeave,
}: Props) {
  if (!label) {
    return null
  }

  return (
    <Container
      dial={4}
      className={className}
      row
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Number className="number" dial={5}>
        {(idx || 0) + 1}
      </Number>

      <Label>{label}</Label>
    </Container>
  )
})

const Container = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  margin-top: 1rem;
  padding-left: 2.625rem;
  text-align: left;
  &:first-of-type {
    margin-top: 0;
  }
  &.active {
    .number {
      background: ${({ theme }) => theme.colors.variants.neutralLight5};
      color: ${({ theme }) => theme.colors.variants.neutralDark3};
    }
  }
`

const Number = styled(FlexBox)`
  width: 1.875rem;
  height: 1.875rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.variants.neutralLight5};
  border-radius: 50%;
  font-size: 0.75rem;
  position: absolute;
  top: 0.125rem;
  left: 0;
  transition: 0.2s ease-in-out;
`

const Label = styled.div`
  font-size: 1.0625rem;
  line-height: 2.125rem;
`
